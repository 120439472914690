//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import url('https://fonts.googleapis.com/css?family=Nunito&text=0123456789');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho&display=swap');

$font-family-base: 'Cormorant Garamond', 'Sawarabi Mincho', sans-serif;

@import "variables";
@import "bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

.font-number{
    font-family: 'Nunito';
}
.font-size-xs{
    font-size: 0.5rem;
}
.font-size-sm{
    font-size: 0.7rem;
}
.font-size-md{
    font-size: 1.2rem;
}
.font-size-lg{
    font-size: 2rem;
}
.font-size-xl{
    font-size: 3rem;
}
.font-size-xxl{
    font-size: 5rem;
}
.border-right
{
    border-right: 1px var(--bs-gray-400) solid;
}
.border-left {
    border-left: 1px var(--bs-gray-500) solid;
}
.border-top{
    border-top: 1px var(--bs-gray-500) solid;
}
.border-bottom{
    border-top: 1px var(--bs-gray-500) solid;
}

hr.wp-block-separator
{
    border-top: 1px solid;
    border-bottom: 0px solid;
    margin: 50px 0;
}
.text-decoration-none a{
	text-decoration: none;
}

a.btn-outline-primary:hover{
    color: $light;
}
//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/wordpress";
@import "components/icomoon";
@import "components/hamburgers";

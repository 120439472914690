.page-content{
    
    font-family: 'Nunito', 'Cormorant Garamond', 'Sawarabi Mincho', sans-serif;
    line-height: 2.0rem;
    .wp-block-image {
        img{
        height: auto;
        }
    }
    .wp-block-embed__wrapper {
        @extend .ratio;
        @extend .ratio-16x9;
    }
    .wp-block-table
    {
      margin: 10px 0;
      td:nth-child(1) {
        width: 30%;
      }
      td{
        padding: 10px;
      }
    }
    figcaption{
        color: $gray-500;
        font-size: 90%;
        text-align: center;
    }    
    h2 {
        font-size: 1.5rem;
        padding: 0.8em 0.5em;
        background: transparent;
        border-left: solid 5px $primary;
        border-bottom: dashed 2px $gray-300;
        color: $gray-700;
        margin-bottom: 1.3rem;
    }
    h3 {
        font-size: 1.3rem;
        padding: 0.6em 0.6em;
        background: transparent;
        margin-bottom: 1.3rem;
        border-bottom: dashed 2px $gray-200;
    }
}
.is-active.hamburger,
.hamburger
{
    .hamburger-inner
    {
        z-index: 1000;
        &.light{
            background-color: $light;
            &:before,
            &:after
            {
                background-color: $light;
            }
        }
        &.dark{
            background-color: $dark;
            &:before,
            &:after
            {
                background-color: $dark;
            }
        }
    }
}
.pagination
{
    a{
        @extend .page-link;
        color: $gray-600;
    }
    span{
        @extend .page-link;
        background-color: $gray-200;
    }
}
